import React from "react";

import Logo from "./assets/Logo/logo.png"

// import Home from "./Page/Index";
// import Navbar from "./compunents/navbar";
// import Footer from "./compunents/footer";
import { GlobalWrapper } from "./presale-gg/context/GlobalWrapper";

function App() {

  return (
    <GlobalWrapper>
      {/* <div className="bg-[#151515] overflow-hidden relative">
        <Navbar />
        <Home />
        <Footer />
      </div> */}
      <div className="flex justify-center items-center bg-[#151515] min-h-[100vh] h-[100%]">
        <div className="max-w-[1040px] w-[100%] flex flex-col py-[50px] items-center justify-center">
          <img src={Logo} alt="" className="max-w-[180px]" /> <br/>
          <div>
            <p className="text-center text-[18px] xs:px-3 text-[#fff]">
            Doge Kombat<br/>
Letter to the Community<br/>
<br/>A big thank you to everyone who participated in our first beta!<br/>
We’ve received tons of valuable feedback, and we're now rebuilding the app from the ground up.<br/>
As a team, we’ve decided to take a new direction to make Doge Kombat the #1 game on Telegram, with the best gameplay and reward system. These updates are expected to take between 3 and 6 months.<br/>
Our vision is to create one of the largest gaming ecosystems and the best crypto game on Telegram. We're also aiming to get listed on Binance and other major exchanges. To achieve that, we need to build something truly groundbreaking.<br/>
We really appreciate everyone who supported us during the pre-sale and all of our early adopters. To ensure you're not left waiting, we will refund everyone who purchased $DOKO so far. Refunds will be sent to the original purchase wallets in the same currency within the next 72 hours.<br/>
If you have any questions, feel free to reach out to @lennydoko on Telegram.<br/>
Stay tuned on Telegram and X for sneak peeks as we prepare for the launch of the most anticipated game in crypto!
<br/>
<br/>
Your Doge Kombat Team
            </p>
          </div>
        </div>
      </div>
    </GlobalWrapper>
  );
}

export default App;
